<template>
  <cities-component />
</template>

<script>
import CitiesComponent from "@/components/admin/catalogs/CitiesComponent";

export default {
  name: "Cities",
  title: "Estados | Baja California Health Tourism",
  components: { CitiesComponent },
};
</script>

<style scoped></style>
